import React from 'react'
import styles from './input.module.css'
interface InputProps {
    value?:any,
    placeholder ?: any,
    error?:any,
    onKeyDown : any;
  }
const InputComponent = ({value,placeholder,error , onKeyDown}:InputProps) => {
  return (
    <div className={styles.inputs}>
        <input type={"text"} onInput={(event) => onKeyDown(event)} placeholder={placeholder} value={value} className={styles.inputstyle}/>
        <span>{error && error}</span>
    </div>
  )
}

export default InputComponent