import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";

import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Image from "next/image";

import Link from "next/link";

import React, { useEffect } from "react";

import navsty from "./navbar.module.css";
import logo from "../../../public/images/logo.png";
import ButtonComponent from "@components/buttonComponent/Button";
import { RenderIf } from "@components/shared/util/renderIf";

const Navbar = ({
  isMetaMaskInstalled,
  metaMaskAddress,
  logoutWallet,
  handleOpen,
}: {
  isMetaMaskInstalled: boolean;
  metaMaskAddress: string | null;
  logoutWallet: any;
  handleOpen: any;
}) => {
  var truncate = function (
    fullStr: string | null,
    strLen: number,
    separator?: string
  ): string | undefined {
    if (fullStr && fullStr.length <= strLen) return fullStr;
    if (fullStr) {
      separator = separator || "...";
      var sepLen = separator.length,
        charsToShow = strLen - sepLen,
        frontChars = Math.ceil(charsToShow / 2),
        backChars = Math.floor(charsToShow / 2);
      return (
        fullStr.substr(0, frontChars) +
        separator +
        fullStr.substr(fullStr.length - backChars)
      );
    }
  };

  return (
    <AppBar position="static" className={`${navsty.navbar}`}>
      <Toolbar disableGutters>
        <Typography
          variant="h5"
          noWrap
          component="span"
          sx={{
            flexGrow: 1,
            textDecoration: "none",
          }}
        >
          <Link href={"https://tehkcitynft.com/"}>
            <Image
              width={"119px"}
              height={"50px"}
              src={logo}
              layout="fixed"
              className="cursor-p"
            />
          </Link>
        </Typography>

        <Box sx={{ flexGrow: 0 }}>
          <Box className="connect-btn">
            <RenderIf isTrue={isMetaMaskInstalled}>
              <ButtonComponent
                text={truncate(metaMaskAddress, 12)}
                // handleClick={() =>  logoutWallet()}
                variant="outlined"
                cls="secondary_wallet"
                meta={false}
                wal={false}
                wbal={true}
                handleLogout={() => logoutWallet()}
              />
            </RenderIf>
            <RenderIf isTrue={!isMetaMaskInstalled}>
              <ButtonComponent
                text="CONNECT  WALLET"
                variant="outlined"
                handleClick={handleOpen}
                cls="secondary"
                meta={false}
                wal={false}
              />
            </RenderIf>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
export default Navbar;
