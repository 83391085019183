import { Grid } from "@mui/material";
import Image from "next/image";
import React, { useEffect, useState } from "react";
import styles from "./home.module.css";
import banner from "../../../public/images/Banner.png";
import ButtonComponent from "@components/buttonComponent/Button";
import FaqList from "@components/FaqListComp/faqComponent";
import ModalComponent from "@components/modalComponent/modal";
import InputComponent from "@components/inputComponent";
import { CLAIM_PROCESS } from "@constants";
import MetaMaskOnboarding from "@metamask/onboarding";
import Header from "@components/shared/Header";
import Navbar from "@components/navbarComponent/Navbar";
import { Storage } from "@components/shared/services/index.service";
import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import REDEEM_CODE from "src/graphql/queries/claim.query";
import { FAQList } from "../../constants/faq.constant";
import { useLoginWithSignatureMutation } from "@graphql-generated";
import {
  getOrganizationId,
  getSignature,
} from "src/graphql/queries/loginQueries";
import { useWeb3ModalConnect } from "@services/Web3ModalConnect";
import GET_NFT_COUNT from "src/graphql/queries/nft.query";
import { RenderIf } from "@components/shared/util/renderIf";

interface HomePageProps {}

const HomeLayout: React.FC<
  HomePageProps
> = ({}: HomePageProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [signatureMessage, setSignatureMessage] = useState<string | null>(null);
  const [accountAddress, setAccountAddress] = useState<null | string>(null);
  const [isWalletConnected, setWalletConnected] = useState(false);
  const [token, setToken] = useState<string | undefined>(undefined);
  const [currentChainId, setCurrentChainId] = useState<number | null>(1);
  const [isWalletSigned, setWalletSigned] = useState<boolean>(false);

  // const { data: orgData } = useQuery(getOrganizationId, {
  //   variables: { org: process.env.NEXT_PUBLIC_ORGANIZATION_NAME },
  // });

  const orgId = process.env.NEXT_PUBLIC_ORGANIZATION_ID;

  const {
    walletConnectProvider,
    networkID,
    chainID,
    getSignedAddress,
    onMetaMaskConnect,
    onWalletConnect,
  } = useWeb3ModalConnect(orgId as string);

  const [loginWithSignatureMutation] = useLoginWithSignatureMutation();

  const _storage = new Storage();
  const [claimCode, setClaimCode] = useState("");
  const [forwarderOrigin, setForwarderOrigin] = useState(
    "http://localhost:9010"
  );
  const [currentClaimProcess, setClaimProcess] = useState(
    CLAIM_PROCESS.CONNECT_WALLET
  );

  /* All Use Query */
  const [refetchSignature, { loading, error }] = useLazyQuery(getSignature);

  const { data } = useQuery(GET_NFT_COUNT, {
    variables: {
      marketplaceID: process.env.NEXT_PUBLIC_MARKETPLACE_ID,
      slug: process.env.NEXT_PUBLIC_SLUG,
    },
  });

  const totalAvailableUnits =
    data?.collectionBySlug?.items[0].details.totalAvailableUnits;
  const totalUnits = data?.collectionBySlug?.items[0].details.totalUnits;

  /* Use Mutation */
  const [reedemCode] = useMutation(REDEEM_CODE, {
    context: {
      headers: {
        Authorization: `Token ${token}`,
      },
    },
  });

  // Check is provider is provided in local storage
  /* All Use Effects */
  // useEffect(() => {
  //   localStorage.clear();
  //   const provider = _storage.getItem("provider");
  //   const token = _storage.getItem("token");
  //   if (provider && token) {
  //     _connectMetaMaskWallet(provider);
  //     _initiateWalletConnect(provider);
  //   }
  // }, []);

  useEffect(() => {
    if (signatureMessage && accountAddress && currentChainId) {
      loginWithSignature();
    }
  }, [signatureMessage, accountAddress, currentChainId]);

  // Public Functions
  function onKeyDown(event: any): void {
    const code = event.target.value;
    setClaimCode(code);
  }

  const handleOpen = async () => {
    setOpen(true);
    if (isWalletConnected) {
      if (String(currentChainId) === process.env.NEXT_PUBLIC_POLYGON_CHAINID) {
        setClaimProcess(CLAIM_PROCESS.CLAIM_CODE);
      } else {
        setClaimProcess(CLAIM_PROCESS.CHAIN_ERROR); // Change the Network in the metamask wallet or wallet connect
      }
    }
  };

  const handleClose = () => {
    if (!isWalletSigned) {
      logoutWallet();
    }
    setClaimCode(CLAIM_PROCESS.CLAIM_CODE);
    setOpen(false);
  };

  async function logoutWallet() {
    setWalletConnected(false);
    setAccountAddress(null);
    setSignatureMessage(null);
    setCurrentChainId(null);
    setWalletSigned(false);
    _storage.clearItem("provider");
    setClaimProcess(CLAIM_PROCESS.CONNECT_WALLET);
  }

  const loginWithSignature = async () => {
    let res = null;
    setOpen(true);
    setClaimProcess(CLAIM_PROCESS.CONNECT_WALLET_LOADING);
    try {
      const challenge = signatureMessage as string;
      const signedAddress = await getSignedAddress(
        challenge,
        accountAddress as string
      );

      if (signedAddress) {
        const { signature, address } = signedAddress;
        const response = await loginWithSignatureMutation({
          variables: {
            request: {
              challenge,
              signature,
              signer: address,
              chainID: currentChainId as number,
            },
            orgID: orgId,
          },
        });
        res = response.data?.loginWithSignature;
        const token = res?.token as string;
        setToken(token);
        setWalletSigned(true);
        sessionStorage.setItem("token", token);
        setOpen(false);
        setClaimProcess(CLAIM_PROCESS.CLAIM_CODE);
      }
    } catch (error) {
      setClaimProcess(CLAIM_PROCESS.SIGNATURE_ERROR);
      setOpen(true);
    }
    return res;
  };

  // WALLET CONNECT CODE
  function _initiateWalletConnect(provider: string): void {
    if (
      provider &&
      typeof provider === "string" &&
      provider === "walletconnect"
    ) {
      let connector = new WalletConnect({
        bridge: "https://bridge.walletconnect.org",
        qrcodeModal: QRCodeModal,
      });

      // Check if connection is already established
      if (!connector.connected) {
        connector.createSession();
      }

      if (connector.connected) {
        _storage.setItem("provider", "walletconnect");
        setCurrentChainId(connector.chainId);
        _walletConnectSuccesfulConnection(connector.accounts[0]);
        _getRefetchSignatiure(
          orgId as string,
          connector.accounts[0] as string,
          networkID
        );
      }

      connector.on("connect", (error, payload) => {
        if (error) {
          throw error;
        }

        const { accounts, chainId } = payload.params[0];
        setCurrentChainId(chainId);
        _walletConnectSuccesfulConnection(accounts[0]);
        _getRefetchSignatiure(
          orgId as string,
          accounts[0] as string,
          networkID
        );
        onWalletConnect();
        _storage.setItem("provider", "walletconnect");
      });

      connector.on("session_update", (error, payload) => {
        if (error) {
          throw error;
        }

        // Get updated accounts and chainId
        const { accounts, chainId } = payload.params[0];
        setCurrentChainId(chainId);
        _walletConnectSuccesfulConnection(accounts[0]);
        _getRefetchSignatiure(
          orgId as string,
          accounts[0] as string,
          networkID
        );
        onWalletConnect();
        _storage.setItem("provider", "walletconnect");
      });

      connector.on("disconnect", (error, payload) => {
        if (error) {
          throw error;
        }
        logoutWallet();
      });
    }
  }

  function _walletConnectSuccesfulConnection(account: string): void {
    setAccountAddress(account);
    setOpen(false);
    setClaimProcess(CLAIM_PROCESS.CLAIM_CODE);
    setWalletConnected(true);
  }

  // META MASK WALLET CODE
  async function _connectMetaMaskWallet(provider: string): Promise<void> {
    // Check if user has logged into meta mask or not
    if (provider && typeof provider === "string" && provider === "metamask") {
      // If Meta Mask Extention is not installed
      if (!_checkIfMetaMaskInstalled()) {
        _onboardUser();
        return;
      }

      // If Meta Mask Extention installed

      onMetaMaskConnect().then(res => {
        _connectToMetaMaskWallet();
      });

      // Event Listeners for accounts changed
      window.ethereum.on(
        "accountsChanged",
        async function (accounts: string[]) {
          setAccountAddress(accounts[0]);
        }
      );

      // Event Listener for chain changed
      window.ethereum.on("chainChanged", async (chainId: any) => {
        const currentChainId = parseInt(chainId, 16);
        setCurrentChainId(currentChainId);
      });
    }
  }

  function _onboardUser(): void {
    const onboarding = new MetaMaskOnboarding({ forwarderOrigin });
    onboarding.startOnboarding();
  }

  async function _connectToMetaMaskWallet(): Promise<void> {
    const windowObj: any = window;
    await windowObj.ethereum
      .request({ method: "eth_requestAccounts" })
      .then(async (res: string[]) => {
        await _getCurrentChainId();
        await _getRefetchSignatiure(orgId as string, res[0], networkID);
        _connectToMetaMaskResponse(res);
      }).catch((err: any) => {
        console.log(err);
      });
  }

  /* Private Functions */
  function _claimNFT(): void {
    reedemCode({ variables: { code: claimCode, destAddr: accountAddress } })
      .then((res: any) => {
        setClaimProcess(CLAIM_PROCESS.CLAIM_CODE_SUCCESFULL);
      })
      .catch((err) => {
        if (err.message === "redeeming limit reached") {
          setClaimProcess(CLAIM_PROCESS.REEDMING_LIMIT_ERROR);
          return;
        }
        if (err.message === "code redeemed") {
          setClaimProcess(CLAIM_PROCESS.CODE_ALREADY_REEDEMED);
          return;
        }
        setClaimProcess(CLAIM_PROCESS.CLAIM_CODE_ERROR);
      });
  }

  async function _getRefetchSignatiure(
    orgId: string,
    walletAddress: string,
    networkID: string | undefined
  ): Promise<void> {
    if (orgId && walletAddress && networkID) {
      const { data, error } = await refetchSignature({
        variables: {
          orgId,
          walletAddress,
          networkID,
        },
      });
      if (data) {
        setSignatureMessage(data.getSignatureMessage);
      }
    }
  }

  async function _getCurrentChainId() {
    const ethereum: any = window.ethereum;
    const currentChain = await ethereum.request({ method: "eth_chainId" });
    const currentChainId = parseInt(currentChain, 16);
    setCurrentChainId(currentChainId);
  }

  function _connectToMetaMaskResponse(res: string[]): void {
    if (res.length > 0) {
      _walletConnectSuccesfulConnection(res[0]);
      _storage.setItem("provider", "metamask");
    }
  }

  function _checkIfMetaMaskInstalled(): boolean {
    const windowObj: any = window;
    return Boolean(windowObj.ethereum && windowObj.ethereum.isMetaMask);
  }

  async function _switchNetworks() {
    const provider = _storage.getItem("provider");
    if (provider && provider === "metamask") {
      await _switchNetworksMetaMask();
      return;
    }
    _switchNetworkWalletConnect();
  }

  async function _switchNetworksMetaMask() {
    window.ethereum
      .request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x89" }],
      })
      .then(async (res: any) => {
        setOpen(false);
        setClaimProcess(CLAIM_PROCESS.CLAIM_CODE);
        await _getCurrentChainId();
        await _getRefetchSignatiure(
          orgId as string,
          accountAddress as string,
          networkID
        );
      })
      .catch((err: any) => {
        if (err.code === 4902) {
          setOpen(true);
          setClaimProcess(CLAIM_PROCESS.POLYGON_NOT_ADDED_ERROR);
        }
      });
  }

  async function _switchNetworkWalletConnect() {
    walletConnectProvider.web3
      .request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x89" }],
      })
      .then((res: any) => {
        console.log(res);
      })
      .catch((err: any) => {
        console.log("err for _switchNetworkWalletConnect", err);
        setOpen(true);
        setClaimProcess(CLAIM_PROCESS.POLYGON_NOT_ADDED_ERROR);
      });
  }

  // JSX Files
  function _renderDialogBoxContainers(type: String): JSX.Element {
    switch (type) {
      case CLAIM_PROCESS.CONNECT_WALLET:
        return _renderConnectWalletContainer();
      case CLAIM_PROCESS.CONNECT_WALLET_LOADING:
        return _renderWalletLoadingContainer();
      case CLAIM_PROCESS.CLAIM_CODE:
        return _renderEnterClaimCodeContainer();
      case CLAIM_PROCESS.CLAIM_CODE_SUCCESFULL:
        return _renderCodeClaimedSuccesfullContainer();
      case CLAIM_PROCESS.POLYGON_NOT_ADDED_ERROR:
        return _renderCodeClaimErrorContainer(
          "Please add Polygon network",
          "In order to claim NFT, please add Polygon network within your Wallet.",
          "Ok",
          null,
          () => {
            setOpen(false);
            setClaimProcess(CLAIM_PROCESS.CLAIM_CODE);
          }
        );
      case CLAIM_PROCESS.CLAIM_CODE_ERROR:
        return _renderCodeClaimErrorContainer(
          "Oops! Something went wrong",
          "Please enter your claim code and try again",
          "Try Again",
          null,
          () => {
            setClaimProcess(CLAIM_PROCESS.CLAIM_CODE);
          }
        );
      case CLAIM_PROCESS.SIGNATURE_ERROR:
        return _renderCodeClaimErrorContainer(
          "Signature request has not been confirmed. ",
          "Please click on CLAIM Code or reconnect your wallet to continue.",
          "OK",
          null,
          () => {
            logoutWallet();
            setOpen(false);
          }
        );
      case CLAIM_PROCESS.CHAIN_ERROR:
        return _renderCodeClaimErrorContainer(
          "Please switch to Polygon network",
          "In order to claim NFT, please switch to Polygon network within your Wallet",
          "Switch Network",
          "Cancel",
          async () => {
            _switchNetworks();
          },
          () => {
            setOpen(false);
            setClaimProcess(CLAIM_PROCESS.CLAIM_CODE);
          }
        );
      case CLAIM_PROCESS.REEDMING_LIMIT_ERROR:
        return _renderCodeClaimErrorContainer(
          "Reedemed Limit Reached",
          "Your wallet has already claimed a nft",
          "OK",
          null,
          () => {
            setClaimProcess(CLAIM_PROCESS.CLAIM_CODE);
          }
        );
      case CLAIM_PROCESS.CODE_ALREADY_REEDEMED:
        return _renderCodeClaimErrorContainer(
          "Code Already Reedemed",
          "This code has been already used , please try again with another code",
          "OK",
          null,
          () => {
            setClaimProcess(CLAIM_PROCESS.CLAIM_CODE);
          }
        );
      case CLAIM_PROCESS.WALLET_NOT_LOGGED_IN_ERROR:
        return _renderCodeClaimErrorContainer(
          "Wallet not logged in !",
          "In order to connect your metamask wallet , Please login first.",
          "Ok",
          null,
          async () => {
            setOpen(false);
            setClaimProcess(CLAIM_PROCESS.CONNECT_WALLET);
          }
        );
      default:
        return _renderConnectWalletContainer();
    }
  }

  function _renderConnectWalletContainer(): JSX.Element {
    return (
      <div className={styles.connect}>
        <h2>Connect Wallet</h2>
        <span>Connect your wallet to claim your NFT</span>
        <ButtonComponent
          text="Metamask"
          variant="contained"
          cls="primary-700"
          meta={true}
          wal={false}
          handleClick={() => _connectMetaMaskWallet("metamask")}
        />
        <ButtonComponent
          text="WalletConnect"
          variant="contained"
          cls="primary-700"
          meta={false}
          wal={true}
          handleClick={() => _initiateWalletConnect("walletconnect")}
        />
      </div>
    );
  }

  function _renderWalletLoadingContainer(): JSX.Element {
    return (
      <div className={styles.loading}>
        <div className="loader"></div>
        <span>
          {" "}
          <b className="b-500">
            Follow wallet prompt to confirm the signature request. Please do not
            close or reload the page.
          </b>
        </span>
      </div>
    );
  }

  function _renderEnterClaimCodeContainer(): JSX.Element {
    return (
      <div className={styles.codeenter}>
        <h2>Enter your code</h2>
        <span>
          Enter the claim code you received after purchasing.{" "}
          <a className="text-dec cursor-p" href="mailto:info@tehkcitynft.com" target="_blank">
            Didn’t receive it?
          </a>
        </span>
        <InputComponent
          onKeyDown={(event: any) => onKeyDown(event)}
          placeholder={"Claim code"}
        />
        <ButtonComponent
          handleClick={() => _claimNFT()}
          text="CLAIM NOW"
          variant="contained"
          cls="primary-800p16"
        />
      </div>
    );
  }

  function _renderCodeClaimedSuccesfullContainer(): JSX.Element {
    return (
      <div className={styles.success}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
        >
          <circle cx="20" cy="20" r="20" fill="#027900" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M29.0959 13.4182C29.6021 13.9538 29.5784 14.7982 29.0428 15.3044L17.75 25.9785C17.2171 26.4822 16.3776 26.4615 15.8702 25.9321L11.37 21.2365C10.8601 20.7045 10.8781 19.8599 11.4101 19.35C11.9421 18.8401 12.7867 18.8581 13.2966 19.3901L16.8799 23.129L27.2098 13.3651C27.7453 12.8589 28.5898 12.8827 29.0959 13.4182Z"
            fill="white"
          />
        </svg>
        <h2>Successfully Claimed</h2>
        <span>Your NFT will be delivered within 15 min to your wallet</span>

        <ButtonComponent
          text="Return to Z2 comics"
          variant="contained"
          cls="primary-800p16"
          handleClick={() => {
            setOpen(false);
            setClaimProcess(CLAIM_PROCESS.CLAIM_CODE);
          }}
        />
      </div>
    );
  }

  function _renderCodeClaimErrorContainer(
    title: string,
    message: string,
    primaryButtonText: string,
    secondaryButtonText: string | null,
    fn: any,
    Sfn?: any,
    isPrimaryBut = true
  ): JSX.Element {
    return (
      <div className={styles.error}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
        >
          <circle cx="20" cy="20" r="20" fill="#EB1D24" />
          <path
            d="M21.4658 20.0001L27.6959 13.7697C28.1014 13.3645 28.1014 12.7092 27.6959 12.3039C27.2907 11.8987 26.6354 11.8987 26.2302 12.3039L19.9999 18.5343L13.7698 12.3039C13.3644 11.8987 12.7093 11.8987 12.3041 12.3039C11.8986 12.7092 11.8986 13.3645 12.3041 13.7697L18.5342 20.0001L12.3041 26.2305C11.8986 26.6358 11.8986 27.291 12.3041 27.6963C12.506 27.8984 12.7716 28 13.037 28C13.3023 28 13.5677 27.8984 13.7698 27.6963L19.9999 21.4659L26.2302 27.6963C26.4323 27.8984 26.6977 28 26.963 28C27.2284 28 27.4938 27.8984 27.6959 27.6963C28.1014 27.291 28.1014 26.6358 27.6959 26.2305L21.4658 20.0001Z"
            fill="white"
            stroke="white"
          />
        </svg>
        <h2>{title}</h2>
        <span>{message}</span>
        <div className={styles.flex}>
          <RenderIf isTrue={isPrimaryBut}>
            <ButtonComponent
              text={primaryButtonText}
              variant="contained"
              cls="primary-800p16"
              handleClick={() => fn()}
            />
          </RenderIf>

          <RenderIf isTrue={!!secondaryButtonText}>
            <ButtonComponent
              text={secondaryButtonText as String}
              variant="contained"
              cls="primary-700"
              handleClick={() => Sfn()}
            />
          </RenderIf>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <RenderIf isTrue={totalAvailableUnits === 0}>
        <div className={styles.notification}>
          Sold out! NFT not available to claim.
        </div>
      </RenderIf>
      <Header title={"Death For Hire"} />

      <Navbar
        logoutWallet={logoutWallet}
        isMetaMaskInstalled={isWalletConnected}
        metaMaskAddress={accountAddress}
        handleOpen={handleOpen}
      />

      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={4} sm={6}>
          <div>
            <div className={styles.loghead}>
              {/* <Link href={"https://tehkcitynft.com/"}>
                <a href="https://tehkcitynft.com/" target="_blank">
                  <Image
                    width={"119px"}
                    height={"50px"}
                    src={logo}
                    layout="fixed"
                    className="cursor-p"
                  />
                </a>
              </Link> */}
            </div>
            <h1>
              Claim your Digital Collectible (NFT) for purchasing the "Death for
              Hire" Platinum Bundle
            </h1>
            <span className="mb-32">
              "Everybody better get ready for the ride of a lifetime—DEATH FOR
              HIRE: The Origin Of Tehk City is coming hot off the presses of Z2
              Comics. Starring the homie Ice-T and a list of stars never before
              seen in one place, get ready to explore the origin leading up to
              the animated series of Tehk City—y’all ain't ready!”
              <br />
              <label className={styles.banner_lab}>
                - Co-creator and co-writer Arabian Prince
              </label>
            </span>
            <RenderIf isTrue={totalAvailableUnits > 0}>
              <ButtonComponent
                text={
                  isWalletConnected ? "CLAIM CODE" : "CONNECT WALLET TO CLAIM"
                }
                variant="contained"
                cls="primary-800"
                handleClick={handleOpen}
                meta={false}
                wal={false}
              />
            </RenderIf>

            {/* <span>
              Available NFTs{" "}
              <b>
                {totalAvailableUnits}/{totalUnits}{" "}
              </b>
            </span> */}
          </div>
        </Grid>
        <Grid item xs={12} md={8} sm={6}>
          <div className={styles.banner_img_wrp}>
            <Image src={banner} objectFit="contain" layout="responsive" />
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={12} sm={12}>
          <h2>FAQs</h2>
          {FAQList.map((faq, index) => (
            <FaqList
              key={index}
              answer={faq.answer}
              question={faq.question}
              isLink={false}
            />
          ))}
          <FaqList
            isLink={true}
            answer={[]}
            question={"Why don’t I see my NFT in my wallet yet?"}
            bflink={
              "There are a variety of reasons you might not see your NFT in your wallet. First, double check that you sent your NFT to the correct wallet address, especially if you have multiple wallets. Also, be aware of network congestion which can prolong the amount of time your NFT will take to show up in your wallet. If you’re still having trouble,"
            }
            link={"mailto:info@tehkcitynft.com"}
            linktxt={"contact us."}
            aflink={""}
          />
          <FaqList
            isCustom={true}
            isLink={false}
            answer={[]}
            question={"Why isn’t my claim code working?"}
            bflink={""}
            link={""}
            linktxt={""}
            aflink={""}
          />
          <FaqList
            isLink={true}
            answer={[]}
            question={"Why can I see my NFT on Opensea but not in Metamask?"}
            bflink={"Metamask is on the Ethereum network by default. Follow"}
            link={
              "https://www.coindesk.com/learn/how-to-connect-metamask-to-the-polygon-network/"
            }
            linktxt={"these steps"}
            aflink={"to add the Polygon network."}
          />
        </Grid>
      </Grid>

      <ModalComponent handleClose={handleClose} open={open}>
        {_renderDialogBoxContainers(currentClaimProcess)}
      </ModalComponent>
    </div>
  );
};

export default HomeLayout;
