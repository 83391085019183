import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import styles from './modal.module.css';


const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'white',
  boxShadow: 24,
  padding:'32px 24px 32px 24px',
  color:'black',
  borderRadius:'4px',
};
interface ModalProps {
  children:any,
  handleClose ?: any,
  open?:any,

}

export default function ModalComponent({children,handleClose,open}:ModalProps) {
  

  return (
    <div>
      
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
            background: '#ffffffd4',
        }}
        >
            
      
        <Box sx={style} className={styles.width}>
          <Typography id="modal-modal-description">
            {children}
          </Typography>
          <span className={styles.close} onClick={handleClose}>
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                <path d="M5.73292 5.00006L8.84796 1.88486C9.05068 1.68223 9.05068 1.3546 8.84796 1.15197C8.64533 0.949342 8.31771 0.949342 8.11509 1.15197L4.99995 4.26717L1.88491 1.15197C1.68219 0.949342 1.35467 0.949342 1.15204 1.15197C0.94932 1.3546 0.94932 1.68223 1.15204 1.88486L4.26708 5.00006L1.15204 8.11526C0.94932 8.31789 0.94932 8.64552 1.15204 8.84815C1.25302 8.94922 1.3858 9 1.51848 9C1.65116 9 1.78384 8.94922 1.88491 8.84815L4.99995 5.73295L8.11509 8.84815C8.21616 8.94922 8.34884 9 8.48152 9C8.6142 9 8.74688 8.94922 8.84796 8.84815C9.05068 8.64552 9.05068 8.31789 8.84796 8.11526L5.73292 5.00006Z" fill="#576CB2" stroke="#576CB2"/>
                </svg>
            </span>
        </Box>
      </Modal>
    </div>
  );
}
