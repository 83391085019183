import * as React from 'react';
import Button from '@mui/material/Button';
import Image from 'next/image';
import buttonstyl from './button.module.css';

import wallet from '../../../public/images/wallet.png';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

interface ButtonProps {
    variant?: any;
    text?: String;
    cls?: any;
    handleClick?: any;
    disable?: any;
    meta?:boolean,
    wal?:boolean, 
    wbal?:boolean,
    handleLogout?: any;
}

const ButtonComponent = ({
    variant,
    text,
    cls,
    handleClick,
    disable,
    meta,
    wal,
    wbal,
    handleLogout,
}: ButtonProps) => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
        <>
            <Button
                variant={variant}
                className={buttonstyl[cls]}
                onClick={handleClick}
                disabled={disable}>

              {
              meta && 
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
              <path d="M16.2031 0.583496L9.95996 5.22036L11.1145 2.48467L16.2031 0.583496Z" fill="#E2761B" stroke="#E2761B" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M1.79066 0.583496L7.9836 5.26428L6.88556 2.48467L1.79066 0.583496ZM13.9569 11.3317L12.2942 13.8792L15.8518 14.858L16.8746 11.3882L13.9569 11.3317ZM1.13184 11.3882L2.14831 14.858L5.70595 13.8792L4.04321 11.3317L1.13184 11.3882Z" fill="#E4761B" stroke="#E4761B" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5.50504 7.02728L4.51367 8.52689L8.04622 8.68375L7.92073 4.88767L5.50504 7.02728ZM12.4886 7.02728L10.0415 4.84375L9.95995 8.68375L13.4862 8.52689L12.4886 7.02728ZM5.70583 13.879L7.82661 12.8438L5.99446 11.4132L5.70583 13.879ZM10.167 12.8438L12.2941 13.879L11.9992 11.4132L10.167 12.8438Z" fill="#E4761B" stroke="#E4761B" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.2943 13.879L10.1672 12.8438L10.3366 14.2304L10.3178 14.8139L12.2943 13.879ZM5.70605 13.879L7.68253 14.8139L7.66998 14.2304L7.82684 12.8438L5.70605 13.879Z" fill="#D7C1B3" stroke="#D7C1B3" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M7.71375 10.4975L5.94434 9.97674L7.19296 9.40576L7.71375 10.4975ZM10.28 10.4975L10.8008 9.40576L12.0557 9.97674L10.28 10.4975Z" fill="#233447" stroke="#233447" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5.70571 13.8795L6.00689 11.332L4.04297 11.3885L5.70571 13.8795ZM11.9928 11.332L12.294 13.8795L13.9567 11.3885L11.9928 11.332ZM13.4861 8.52734L9.95983 8.68421L10.2861 10.4975L10.8069 9.40578L12.0618 9.97675L13.4861 8.52734ZM5.94415 9.97675L7.19905 9.40578L7.71356 10.4975L8.04611 8.68421L4.51356 8.52734L5.94415 9.97675Z" fill="#CD6116" stroke="#CD6116" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M4.51367 8.52734L5.99446 11.4136L5.94426 9.97675L4.51367 8.52734ZM12.0619 9.97675L11.9992 11.4136L13.4862 8.52734L12.0619 9.97675ZM8.04622 8.68421L7.71367 10.4975L8.12779 12.6371L8.22191 9.81989L8.04622 8.68421ZM9.95995 8.68421L9.79053 9.81362L9.86583 12.6371L10.2862 10.4975L9.95995 8.68421Z" fill="#E4751F" stroke="#E4751F" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M10.2863 10.4973L9.8659 12.637L10.1671 12.844L11.9992 11.4134L12.062 9.97656L10.2863 10.4973ZM5.94434 9.97656L5.99453 11.4134L7.82669 12.844L8.12786 12.637L7.71375 10.4973L5.94434 9.97656Z" fill="#F6851B" stroke="#F6851B" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M10.3178 14.8143L10.3366 14.2308L10.1798 14.0927H7.81429L7.66998 14.2308L7.68253 14.8143L5.70605 13.8794L6.39625 14.4441L7.79547 15.4166H10.1986L11.6041 14.4441L12.2943 13.8794L10.3178 14.8143Z" fill="#C0AD9E" stroke="#C0AD9E" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M10.1672 12.8438L9.866 12.6367H8.12796L7.82678 12.8438L7.66992 14.2304L7.81423 14.0924H10.1797L10.3366 14.2304L10.1672 12.8438Z" fill="#161616" stroke="#161616" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M16.4667 5.52154L17 2.96154L16.2031 0.583496L10.1671 5.0635L12.4886 7.02742L15.7702 7.98742L16.498 7.14036L16.1843 6.91448L16.6863 6.45644L16.2973 6.15526L16.7992 5.77252L16.4667 5.52154ZM1 2.96154L1.53333 5.52154L1.19451 5.77252L1.69647 6.15526L1.31373 6.45644L1.81569 6.91448L1.50196 7.14036L2.22353 7.98742L5.5051 7.02742L7.82667 5.0635L1.79059 0.583496L1 2.96154Z" fill="#763D16" stroke="#763D16" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M15.7703 7.98761L12.4887 7.02761L13.4863 8.52722L11.9993 11.4135L13.9569 11.3884H16.8746L15.7703 7.98761ZM5.50517 7.02761L2.2236 7.98761L1.13184 11.3884H4.04321L5.99458 11.4135L4.5138 8.52722L5.50517 7.02761ZM9.96007 8.68408L10.1671 5.06369L11.1209 2.48486H6.88556L7.82674 5.06369L8.04635 8.68408L8.12164 9.82604L8.12791 12.637H9.86595L9.8785 9.82604L9.96007 8.68408Z" fill="#F6851B" stroke="#F6851B" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              }  
              {
                wal &&
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.5 0C12.9187 0 16.5 3.58125 16.5 8C16.5 12.4187 12.9187 16 8.5 16C4.08125 16 0.5 12.4187 0.5 8C0.5 3.58125 4.08125 0 8.5 0Z" fill="url(#paint0_radial_149_2607)"/>
                <path d="M5.58447 6.17793C7.19385 4.60605 9.80635 4.60605 11.4157 6.17793L11.6095 6.36855C11.6907 6.44668 11.6907 6.5748 11.6095 6.65293L10.947 7.2998C10.9063 7.34043 10.8407 7.34043 10.8001 7.2998L10.5345 7.04043C9.40947 5.94355 7.59072 5.94355 6.46572 7.04043L6.18135 7.31856C6.14072 7.35918 6.0751 7.35918 6.03447 7.31856L5.37197 6.67168C5.29072 6.59355 5.29072 6.46543 5.37197 6.3873L5.58447 6.17793ZM12.7876 7.51543L13.3782 8.09043C13.4595 8.16855 13.4595 8.29668 13.3782 8.3748L10.7188 10.9717C10.6376 11.0498 10.5063 11.0498 10.4282 10.9717L8.54072 9.12793C8.52197 9.10918 8.4876 9.10918 8.46885 9.12793L6.58135 10.9717C6.5001 11.0498 6.36885 11.0498 6.29072 10.9717L3.62197 8.3748C3.54072 8.29668 3.54072 8.16855 3.62197 8.09043L4.2126 7.51543C4.29385 7.4373 4.4251 7.4373 4.50322 7.51543L6.39072 9.35918C6.40947 9.37793 6.44385 9.37793 6.4626 9.35918L8.3501 7.51543C8.43135 7.4373 8.5626 7.4373 8.64072 7.51543L10.5282 9.35918C10.547 9.37793 10.5813 9.37793 10.6001 9.35918L12.4876 7.51543C12.5751 7.4373 12.7063 7.4373 12.7876 7.51543Z" fill="white"/>
                <defs>
                <radialGradient id="paint0_radial_149_2607" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(0.50005 8.00025) scale(16)">
                <stop stop-color="#5D9DF6"/>
                <stop offset="1" stop-color="#006FFF"/>
                </radialGradient>
                </defs>
                </svg>
              }
              {
                wbal &&
                <i>
                    <Image src={wallet} />
                </i>
              }
              
              {text}
              {
                wbal &&
                
              <div>
                <IconButton 
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleOpen}
                    >
                    <MoreVertIcon className='icon'/>
                        </IconButton>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                        'aria-labelledby': 'basic-button',
                        }}
                        className="diconnect"
                    >
                        <MenuItem onClick={handleLogout} >Disconnect</MenuItem>
                        
                        
                    </Menu>
               </div>
                }
              
            </Button>
        </>
    );
};

export default ButtonComponent;
