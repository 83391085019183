import { gql } from "@apollo/client";

export const getOrganizationId = gql`
  query ($org: String!) {
    organization(handle: $org) {
      id
    }
  }
`;

export const supportedNetworks = gql`
  query ($orgId: UUID1!) {
    getSupportedNetworks(orgId: $orgId, includeTestnets: true) {
      id
      name
      chainID
      rpcURL
    }
  }
`;

export const getSignature = gql`
  query ($orgId: UUID1!, $walletAddress: String!, $networkID: UUID1!) {
    getSignatureMessage(
      orgID: $orgId
      walletAddress: $walletAddress
      networkID: $networkID
    )
  }
`;

export const loginOrSignup = gql`
  mutation (
    $challenge: String!
    $signature: String!
    $signer: String!
    $chainId: Int!
    $orgId: UUID1!
  ) {
    loginWithSignature(
      request: {
        challenge: $challenge
        signature: $signature
        signer: $signer
        chainID: $chainId
      }
      orgID: $orgId
    ) {
      token
    }
  }
`;

export const loginOrSignupWithNameEmail = gql`
  mutation (
    $challenge: String!
    $signature: String!
    $signer: String!
    $chainId: Int!
    $orgId: UUID1!
    $name: String!
    $email: String!
  ) {
    loginWithSignature(
      request: {
        challenge: $challenge
        signature: $signature
        signer: $signer
        chainID: $chainId
        name: $name
        email: $email
      }
      orgID: $orgId
    ) {
      token
    }
  }
`;
