export class Storage {


    public setItem(key: string,value: any): void{
        sessionStorage.setItem(key,JSON.stringify(value));
    }

    public getItem(key: string): any{
        try{
            return JSON.parse(sessionStorage.getItem(key) as string);
        }catch(e){
            return sessionStorage.getItem(key) as string;
        }
    }

    public clearItem(key: string): void{
        sessionStorage.removeItem(key);
    }

    public clearStorage(): void{
        sessionStorage.clear();
    }
}