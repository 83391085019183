import { Web3Provider, JsonRpcSigner } from "@ethersproject/providers";
import { useGetSupportedNetworksQuery } from "@graphql-generated";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { useCallback, useEffect, useState } from "react";
import Web3Modal, { IProviderOptions } from "web3modal";
import { Signer } from "@ethersproject/abstract-signer";
import { createContext, useContext } from "react";

export interface ConnectType {
  connected: boolean;
  chainId: number;
  signer?: Signer;
  provider?: Web3Provider;
  account: string;
  modal?: Web3Modal;
}
export interface ContextType {
  connect: ConnectType;
  setConnect(f: ConnectType | ((prev: ConnectType) => ConnectType)): void;
}

const Context = createContext<ContextType>({connect: {}, setConnect: (connect) => {}} as ContextType);

export default Context;

export const useConnect = () => {
  return useContext(Context);
};

export const setupAll = async (tag: string) => {
  const providerOptions =
    tag == "wallet"
      ? {
          walletconnect: {
            package: WalletConnectProvider,
            options: {
              infuraId: process.env.NEXT_PUBLIC_WALLETCONNECT_ID,
              rpc : {
                137: "https://rpc-mainnet.maticvigil.com/",
              }
            },
          },
        }
      : {
          walletconnect: {
            package: undefined,
            options: {
              infuraId: process.env.NEXT_PUBLIC_WALLETCONNECT_ID,
            },
          },
        };

  const web3Modal = new Web3Modal({
    cacheProvider: true, // optional
    providerOptions: providerOptions as IProviderOptions, // required
  });
  // web3Modal.clearCachedProvider();
  return web3Modal;
};

export const onConnect = async (web3Modal: Web3Modal, tag: string) => {
  if (web3Modal) {
    try {
      const provider =
        tag == "metamask"
          ? await web3Modal.connect()
          : await web3Modal.connectTo("walletconnect");
      const web3Provider = new Web3Provider(provider);
      const signedAddress =
        (await provider.selectedAddress) || provider.accounts[0];
      return {
        provider: web3Provider,
        web3: provider,
        account: signedAddress,
        success: true,
      };
    } catch (err) {
      console.log("error connecting Web3: ", err);
    }
  } else {
    console.log("No Web3Modal...");
  }
};

export const useWeb3ModalConnect = (orgID: string) => {
  const [ connect, setConnect ] = useState({} as ConnectType);
  const [networkID, setNetworkID] = useState<string | undefined>(process.env.NEXT_PUBLIC_NETWORK_ID);
  const [chainID, setChainID] = useState<number | undefined>(137);
  const [address,setAddress] = useState<string | null>(null);
  const [walletConnectProvider,setWalletConnectProvider] = useState<any>(null);
  // const { data: supportedNetworksData } = useGetSupportedNetworksQuery({
  //   skip: !orgID,
  //   variables: {
  //     orgID,
  //     includeTestnets: true,
  //   },
  // });

  // useEffect(() => {
  //   if (supportedNetworksData?.getSupportedNetworks) {
  //     const supportedNetwork = supportedNetworksData?.getSupportedNetworks;
  //     setNetworkID(supportedNetwork[0].id);
  //     setChainID(supportedNetwork[0].chainID)
  //   }
  // }, [supportedNetworksData, setNetworkID]);


  const getSignedAddress = useCallback(
    async (message: string, account: string) => {
      const signer: JsonRpcSigner | undefined = connect?.provider?.getSigner(
        account
      );
      if (signer) {
        const signature = await signer.signMessage(message);
        const address = await signer.getAddress();
        return { address, signature };
      }
    },
    [connect]
  );

  const onWalletConnect = useCallback(async () => {
    localStorage.clear();
    const modal = await setupAll("wallet");

    const provider = await onConnect(modal, "wallet");

    if (provider) {
      let chainId = provider.web3.chainId;
      if (typeof chainId === "string") {
        chainId = parseInt(chainId, 16);
      }
      setWalletConnectProvider(provider);
      setConnect({
        account: provider.account,
        signer: provider.provider.getSigner(provider.account),
        provider: provider.provider,
        connected: provider.success,
        chainId,
        modal,
      });
    }
  }, [setConnect]);

  const onMetaMaskConnect = useCallback(async () => {
    localStorage.clear();
    const modal = await setupAll("metamask");
    const provider = await onConnect(modal, "metamask");
    console.log(provider);
    if (provider) {
      let chainId = provider.web3.chainId;
      if (typeof chainId === "string") {
        chainId = parseInt(chainId, 16);
      }
  
      setConnect({
        account: provider.account,
        signer: provider.provider.getSigner(provider.account),
        provider: provider.provider,
        connected: provider.success,
        chainId,
        modal,
      });
    }
  }, [setConnect]);

  return {
    walletConnectProvider,
    connect,
    setConnect,
    networkID,
    chainID,
    onWalletConnect,
    getSignedAddress,
    onMetaMaskConnect,
  };
};
