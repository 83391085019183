import HomeLayout from "@layouts/home";
import type { NextPage } from "next";
import Head from "next/head";

interface HomePageProps {}

const Home: NextPage<HomePageProps> = ({}: HomePageProps) => {
  return (
    <>
      <HomeLayout />;
    </>
  );
};

export default Home;
