import { RenderIf } from "@components/shared/util/renderIf";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import React from "react";
import "./faq.module.css";
import Link from "next/link";

interface FaqListProps {
  question: string;
  answer?: any;
  isLink: boolean;
  bflink?: string;
  link?: string;
  linktxt?: string;
  aflink?: string;
  isCustom?: boolean;
}

export default function FaqList({
  question,
  answer,
  isLink,
  bflink,
  link,
  linktxt,
  aflink,
  isCustom = false,
}: FaqListProps): JSX.Element {
  return (
    <div className="faqlist_wrap">
      <Accordion>
        <AccordionSummary
          expandIcon={<Icon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography component="div">
            <p>{question}</p>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography component="div">
            <ul>
              {answer.map((ans: string, index: number) => (
                <li key={index}>
                  <i>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="9"
                      height="12"
                      viewBox="0 0 9 12"
                      fill="none"
                    >
                      <path d="M9 6L6.67572e-06 12V0L9 6Z" fill="#EB1D24" />
                      <path
                        d="M6.75 6L4.29153e-06 10.5L4.29153e-06 1.5L6.75 6Z"
                        fill="#242424"
                      />
                      <path
                        d="M4.54663 6L0.0466342 9V3L4.54663 6Z"
                        fill="#EB1D24"
                      />
                    </svg>
                  </i>
                  <span>{ans}</span>
                </li>
              ))}
              <li>
                {isLink ? (
                  <React.Fragment>
                    <i>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="9"
                        height="12"
                        viewBox="0 0 9 12"
                        fill="none"
                      >
                        <path d="M9 6L6.67572e-06 12V0L9 6Z" fill="#EB1D24" />
                        <path
                          d="M6.75 6L4.29153e-06 10.5L4.29153e-06 1.5L6.75 6Z"
                          fill="#242424"
                        />
                        <path
                          d="M4.54663 6L0.0466342 9V3L4.54663 6Z"
                          fill="#EB1D24"
                        />
                      </svg>
                    </i>
                    <span>
                      {/* There are a variety of reasons you might not see your NFT
                      in your wallet. First, double check that you sent your NFT
                      to the correct wallet address, especially if you have
                      multiple wallets. Also, be aware of network congestion
                      which can prolong the amount of time your NFT will take to
                      show up in your wallet. If you’re still having trouble, */}
                      {bflink}{" "}
                      <a href={link}>
                        <u style={{ color: "#fff", fontWeight: "700" }}>
                          {linktxt}
                        </u>
                      </a>{" "}
                      {aflink && aflink}
                    </span>
                  </React.Fragment>
                ) : null}
              </li>
              <RenderIf isTrue={isCustom}>
                <li>
                  <i>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="9"
                      height="12"
                      viewBox="0 0 9 12"
                      fill="none"
                    >
                      <path d="M9 6L6.67572e-06 12V0L9 6Z" fill="#EB1D24" />
                      <path
                        d="M6.75 6L4.29153e-06 10.5L4.29153e-06 1.5L6.75 6Z"
                        fill="#242424"
                      />
                      <path
                        d="M4.54663 6L0.0466342 9V3L4.54663 6Z"
                        fill="#EB1D24"
                      />
                    </svg>
                  </i>
                  <span>
                    The best way to claim is on desktop instead of mobile.
                    First, make sure you’ve added the Polygon Network to your
                    wallet and your wallet plug-in to your browser. Click {"  "}
                    <a href={"https://metamask.io/download"}>
                      <u style={{ color: "#fff", fontWeight: "700" }}>here </u>
                    </a>{" "}
                    to install Metamask in your browser and follow 
                    <a href={"https://www.coindesk.com/learn/how-to-connect-metamask-to-the-polygon-network"}>
                      <u style={{ color: "#fff", fontWeight: "700" }}>{' '}these{' '}</u>
                    </a>steps
                    to add the Polygon network. Next, check to see if your
                    browser is blocking pop-ups. This can prevent your ability
                    to accept the necessary signature within your wallet. Double
                    check you are entering the correct code. If you are still
                    having issues,
                    <a href={"mailto:info@tehkcitynft.com"}>
                      <u style={{ color: "#fff", fontWeight: "700" }}>
                        contact us
                      </u>
                    </a>
                  </span>
                </li>
              </RenderIf>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <div></div>
    </div>
  );
}

function Icon() {
  return (
    <i className="drop-icn">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="8"
        viewBox="0 0 14 8"
        fill="none"
      >
        <path d="M13 1L7 7L1 1" stroke="white" strokeWidth="1.4" />
      </svg>
    </i>
  );
}
