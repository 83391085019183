import {gql} from '@apollo/client';

const GET_NFT_COUNT = gql`
query($marketplaceID:UUID1!,$slug:String!) {
    collectionBySlug(marketplaceID:$marketplaceID,slug:$slug){
      items {
        id,
        name,
        details {
          ... on MarketplaceClaimableOutput {
            id
            perWalletLimit,
            claimingType,
            totalUnits,
            marketplaceCollectionItem {
                id,
              slug
            },
            totalAvailableUnits
          }
        }
      }
    }
  }
`;

export default GET_NFT_COUNT;