export const FAQList = [
    {
        question : 'What is Death for Hire?',
        answer : ['DEATH FOR HIRE: The Origin of Tehk City, is a new hard-crime graphic novel prelude to a massive IP universe that will span animation, music, toys, NFTs, and beyond. Created by iconic musician and actor Ice-T, founding NWA member Arabian Prince, and Award-Winning artist Tommy The Animator, the graphic novel introduces a brutal ensemble of pushers and murderers in a world where the line between anti-hero and arch-villain blurs quickly.',]
    },
    {
        question : 'What is an NFT?',
        answer : ['Non-Fungible tokens, or NFTs, represent unique digital items. More specifically, they are non-interchangeable units of data stored on a blockchain that can be sold and transferred with ease in the future.']
    },
    {
        question : 'Why is it an NFT?',
        answer : ['Holders of these NFTs can enjoy special benefits like early access to product launches and exclusive entry passes for virtual and in-person events. Above all, utility NFTs provide access to exclusive communities with loads of rewards and incentives.']
    },
    {
        question : 'Where do I store my NFT?',
        answer : ['A crypto currency wallet is a software or device that allows fans to interact with blockchain networks. Ensuring your NFTs are kept in a secure place is important, especially if you plan on accessing the benefits of your tokens or want to store them as collectibles or investments.']
    },
    {
        question : 'How do I use the utility?',
        answer : ['Storing your NFT in a cryptocurrency wallet will allow token fans to access a variety of experiences by connecting the wallet to the utility item as proof of ownership.']
    },
    {
        question : 'Can I sell my NFT?',
        answer : ['You are the owner of the NFT, so you can sell it at any time using a peer to peer marketplace like OpenSea.']
    },
    {
        question : 'Which blockchain do these NFTs live on?',
        answer : ['Polygon.']
    },
    {
        question : 'Can I claim on mobile?',
        answer : ['Yes, but you must use the browser within the wallet app.']
    },
];
